<template>
  <label class="flex cursor-pointer gap-2 text-sm" :data-form-field="name">
    <app-pop-transition mode="out-in">
      <app-icon
        :key="icon"
        class="shrink-0"
        :class="{
          'text-error': errorMessage,
        }"
        :icon="icon"
      />
    </app-pop-transition>

    <app-icon class="!hidden" icon="ph:check-square-fill" />
    <app-icon class="!hidden" icon="ph:square" />

    <span class="pt-[2px]">
      <slot>
        {{ label }}
      </slot>
    </span>

    <input v-model="value" class="sr-only" type="checkbox" />
  </label>
</template>

<script lang="ts" setup>
import { useField } from "vee-validate";
import type { TranslateResult } from "vue-i18n";

import { uniqFieldName } from "../composables/form-input.hook";

const properties = defineProps<{
  modelValue?: boolean;
  label?: TranslateResult;
  name?: string;
  required?: boolean;
}>();

const name = computed(
  () => properties.name ?? properties.label ?? uniqFieldName(),
);
const { value, errorMessage } = useField(name.value, {
  required: properties.required,
});

const icon = computed(() =>
  value.value ? "ph:check-square-fill" : "ph:square",
);
</script>
